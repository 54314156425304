import * as Queries from "./Queries";
import { gql, useMutation, useQuery } from "@apollo/client";
import Swal from "sweetalert2";
import { useState } from "react";

import { Col, Row, Layout, Typography, Button, Tabs, Skeleton } from "antd";
import { useHistory } from "react-router-dom";

import UserInfo from "./components/UserInfo";
import UserDocuments from "./components/UserDocuments";
import Business from "./components/Business";
import Address from "./components/Address";
import Contract from "./components/Contract";
import Documents from "./components/Documents";
import AddBusiness from "./AddBusiness";

const { Content } = Layout;
const { Title } = Typography;

function User(props) {
	const history = useHistory();
	const { id } = props.match.params;
	const [removeAction, { loading }] = useMutation(Queries.USER_REMOVE);
	const [addBusinessVisible, setAddBusinessVisible] = useState(false);

	const businesses = useQuery(
		gql`
		query UserBusinesses($uid: String!)	 {
			UserBusinesses(uid: $uid) {
				_id
				company_name
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
			variables: {
				uid: id,
			},
		}
	);

	console.log(businesses);

	const remove = () => {
		Swal.fire({
			icon: "warning",
			title: "Remover Usuário",
			text: "Tem certeza que deseja remover este usuário? Esta ação é irreversível.",
			showCancelButton: true,
			confirmButtonText: "Sim",
			denyButtonText: `Cancelar`,
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await removeAction({
					variables: {
						id,
					},
				});

				if (res.data.UserRemove) {
					history.push("/users");
				} else {
					Swal.fire({
						icon: "error",
						title: "Erro",
						text: "Não foi possível remover este usuário.",
					});
				}
			}
		});
	};

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Sobre o usuário</Title>

					<div style={{ display: "flex" }}>
						<Button
							type="primary"
							style={{ marginLeft: 10 }}
							onClick={remove}
							danger
						>
							Remover Usuário
						</Button>
					</div>
				</div>

				<UserInfo id={id} />
				<UserDocuments id={id} />

				{businesses.loading && (
					<>
						<Skeleton />
						<Skeleton />
						<Skeleton />
					</>
				)}

				<AddBusiness
					visible={addBusinessVisible}
					uid={id}
					close={(shouldRefetch) => {
						setAddBusinessVisible(false);
						if (shouldRefetch) {
							businesses.refetch();
						}
					}}
				/>

				{businesses?.data?.UserBusinesses?.length ? (
					<div>
						<Tabs
							type="editable-card"
							tabBarGutter={10}
							onEdit={(e) => {
								setAddBusinessVisible(true);
							}}
						>
							{businesses?.data?.UserBusinesses.map((business) => {
								return (
									<Tabs.TabPane
										tab={business.company_name || "Empresa"}
										key={business._id}
										closable={false}
									>
										<Business
											id={id}
											business_id={business._id}
											refetchParent={businesses.refetch}
										/>

										<Address id={id} business_id={business._id} />

										<Row gutter={20}>
											<Col span={12}>
												<Documents id={id} business_id={business._id} />
											</Col>
											<Col span={12}>
												<Contract id={id} business_id={business._id} />
											</Col>
										</Row>
									</Tabs.TabPane>
								);
							})}
						</Tabs>
					</div>
				) : (
					<div>user has no biz</div>
				)}
			</Content>
		</Layout>
	);
}

export default User;
