import React, { useState, useEffect } from "react";
import * as Queries from "./Queries";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import {
	Modal,
	Button,
	Col,
	Row,
	Form,
	Select,
	Upload,
	Descriptions,
	Divider,
	Input,
	Typography,
	DatePicker,
	notification,
} from "antd";
import {
	UploadOutlined,
	FileDoneOutlined,
	FilePdfFilled,
} from "@ant-design/icons";
import { useImmer } from "use-immer";
import Swal from "sweetalert2";
import MaskedInput from "antd-mask-input";
import { useDebounce } from "@react-hook/debounce";
import axios from "axios";
import Estados from "functions/states";
import BusinessStatus from "./util/BusinessStatus";

const { Title } = Typography;

function ModalView({ visible, refetch, close, data, uid }) {
	const [saveAction] = useMutation(Queries.ADD_BUSINESS);
	const [cepLoading, setCepLoading] = useState(false);
	const [cep, setCep] = useDebounce(null, 500);
	const [loading, setLoading] = useState(false);

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const defaultState = {
		user_documents: {},
		business: {
			status: "1",
		},
		address: {},
		contract: {},
		documents: {},
	};

	const [state, setState] = useImmer({...defaultState});

	const onClose = () => {
		close();
	};

	const save = async () => {
		try {
			// User documents
			// if (!state?.business?.company_name)
			// 	return ErrorDialog("Por favor, informe a razão social.");
			// if (!state?.business?.cnpj)
			// 	return ErrorDialog("Por favor, informe o CNPJ.");

			let obj = { ...state };
			obj.documents = state.documents;

			setLoading(true);

			const { data, error } = await saveAction({
				variables: {
					input: obj,
					uid,
				},
			});

			setLoading(false);

			if (error || !data.AddBusiness.result) {
				let errorMsg =
					data.AddBusiness.error ||
					"Não foi possível criar a empresa no momento.";
				return ErrorDialog(errorMsg);
			} else {
				notification.success({
					message: "Sucesso",
					description: "Empresa criada com sucesso",
				});
				close(true);
				setState(defaultState);
			}
		} catch (e) {
			setLoading(false);
			console.log(e);
			return ErrorDialog("Não foi possível criar a empresa no momento.");
		}
	};

	useEffect(() => {
		async function getCep(cep) {
			let code = cep.replace(/\D/g, "");

			if (code.length === `00000000`.length) {
				setCepLoading(true);
				let response = await axios.get(
					`https://viacep.com.br/ws/${code}/json/`
				);
				setCepLoading(false);
				if (response?.data) {
					setState((draft) => {
						draft.address.street = response.data.logradouro;
						draft.address.neighborhood = response.data.bairro;
						draft.address.city = response.data.localidade;
						draft.address.state = response.data.uf;
					});
				} else {
					return ErrorDialog("Não foi possível carregar o CEP");
				}
			}
		}
		if (cep) getCep(cep);
	}, [cep]);

	return (
		<Modal
			title="Adicionar Empresa"
			visible={visible}
			onOk={save}
			width={1000}
			onCancel={onClose}
			footer={[
				<Button key="back" onClick={onClose} disabled={loading}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loading}
					disabled={loading}
					onClick={save}
				>
					Salvar
				</Button>,
			]}
		>
			<Form layout="vertical">
				<Title level={3}>Empresa</Title>

				<Row gutter={20}>
					<Col span={16}>
						<Form.Item name="razao" label="Razão Social">
							<Input
								defaultValue={state?.business?.company_name}
								onChange={(e) => {
									setState((draft) => {
										draft.business.company_name = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item name="cnpj" label="CNPJ">
							<MaskedInput
								mask={"11.111.111/1111-11"}
								defaultValue={state?.business?.cnpj}
								onChange={(e) => {
									setState((draft) => {
										draft.business.cnpj = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item name="trade_name" label="Nome Fantasia">
							<Input
								defaultValue={state?.business?.trade_name}
								onChange={(e) => {
									setState((draft) => {
										draft.business.trade_name = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>
					
					<Col span={12}>
						<Form.Item name="business_email" label="E-mail da Empresa">
							<Input
								defaultValue={state?.business?.email}
								onChange={(e) => {
									setState((draft) => {
										draft.business.email = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item name="municipal_registration" label="Inscrição Municipal">
							<Input
								defaultValue={state?.business?.municipal_registration}
								onChange={(e) => {
									setState((draft) => {
										draft.business.municipal_registration = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Divider />

				<Title level={3}>Endereço Comercial</Title>

				<Row gutter={20}>
					<Col span={6}>
						<Form.Item label="CEP">
							<MaskedInput
								mask={"11111-111"}
								loading={cepLoading}
								defaultValue={state?.address?.zip_code}
								onChange={(e) => {
									setState((draft) => {
										draft.address.zip_code = e.target.value;
									});
									setCep(e.target.value);
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item label="Endereço">
							<Input
								value={state?.address?.street}
								onChange={(e) => {
									setState((draft) => {
										draft.address.street = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Número">
							<Input
								value={state?.address?.number}
								onChange={(e) => {
									setState((draft) => {
										draft.address.number = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label="Complemento">
							<Input
								value={state?.address?.complement}
								onChange={(e) => {
									setState((draft) => {
										draft.address.complement = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item label="Bairro">
							<Input
								value={state?.address?.neighborhood}
								onChange={(e) => {
									setState((draft) => {
										draft.address.neighborhood = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item label="Cidade">
							<Input
								value={state?.address?.city}
								onChange={(e) => {
									setState((draft) => {
										draft.address.city = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item label="Estado">
							<Select
								value={state?.address?.state}
								onChange={(e) => {
									setState((draft) => {
										draft.address.state = e;
									});
								}}
							>
								{Estados("all").map((item, index) => {
									return (
										<Select.Option key={`state_${index}`} value={item.value}>
											{item.text}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Divider />

				<Title level={3}>Contrato</Title>

				<Row gutter={20}>
					<Col span={12}>
						<Form.Item name="sign_date" label="Data de Assinatura">
							<MaskedInput
								mask={"11/11/11111"}
								onChange={(e) => {
									setState((draft) => {
										draft.contract.signature_date = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="responsibility_start"
							label="Data de Início de Responsabilidade"
						>
							<DatePicker
								format={"MM/YYYY"}
								picker="month"
								onChange={(e) => {
									setState((draft) => {
										draft.contract.responsibility_start = e.toDate();
									});
								}}
								style={{
									width: "100%",
								}}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Divider />

				<Title level={3}>Documentos</Title>

				<Row gutter={20}>
					<Col span={24}>
						<Row gutter={30}>
							<Col span={6}>
								<Form.Item name={"alvara"} label={"Alvará"}>
									<Upload
										name={"alvara"}
										beforeUpload={() => false}
										onChange={(e) => {
											const { file } = e;
											setState((draft) => {
												draft.documents.alvara = file;
											});
										}}
									>
										<Button icon={<UploadOutlined />}>Enviar</Button>
									</Upload>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item name={"cartao_cnpj"} label={"Cartão CNPJ"}>
									<Upload
										name={"cartao_cnpj"}
										beforeUpload={() => false}
										onChange={(e) => {
											const { file } = e;
											setState((draft) => {
												draft.documents.cartao_cnpj = file;
											});
										}}
									>
										<Button icon={<UploadOutlined />}>Enviar</Button>
									</Upload>
								</Form.Item>
							</Col>
							
							<Col span={6}>
								<Form.Item name={"rg"} label={"RG"}>
									<Upload
										name={"rg"}
										beforeUpload={() => false}
										onChange={(e) => {
											const { file } = e;
											setState((draft) => {
												draft.documents.rg = file;
											});
										}}
									>
										<Button icon={<UploadOutlined />}>Enviar</Button>
									</Upload>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item name={"cpf"} label={"CPF"}>
									<Upload
										name={"cpf"}
										beforeUpload={() => false}
										onChange={(e) => {
											const { file } = e;
											setState((draft) => {
												draft.documents.cpf = file;
											});
										}}
									>
										<Button icon={<UploadOutlined />}>Enviar</Button>
									</Upload>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item name={"cnh"} label={"CNH"}>
									<Upload
										name={"cnh"}
										beforeUpload={() => false}
										onChange={(e) => {
											const { file } = e;
											setState((draft) => {
												draft.documents.cnh = file;
											});
										}}
									>
										<Button icon={<UploadOutlined />}>Enviar</Button>
									</Upload>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item name={"titulo_eleitor"} label={"Título de Eleitor"}>
									<Upload
										name={"titulo_eleitor"}
										beforeUpload={() => false}
										onChange={(e) => {
											const { file } = e;
											setState((draft) => {
												draft.documents.titulo_eleitor = file;
											});
										}}
									>
										<Button icon={<UploadOutlined />}>Enviar</Button>
									</Upload>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item name={"iptu"} label={"IPTU"}>
									<Upload
										name={"iptu"}
										beforeUpload={() => false}
										onChange={(e) => {
											const { file } = e;
											setState((draft) => {
												draft.documents.iptu = file;
											});
										}}
									>
										<Button icon={<UploadOutlined />}>Enviar</Button>
									</Upload>
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}

export default ModalView;
