import { useState } from "react";
import * as Queries from "../Queries";
import { useQuery, useMutation, useLazyQuery, gql } from "@apollo/client";
import Swal from "sweetalert2";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import _ from "lodash";

import {
	Col,
	Row,
	Layout,
	Typography,
	Button,
	Card,
	Form,
	Input,
	Select,
	notification,
	Divider,
	Upload,
} from "antd";
import MaskedInput from "antd-mask-input";
import PartnerSearch from "components/PartnerSearch";
import { UploadOutlined } from "@ant-design/icons";

const { TextArea } = Input;

function User({ id }) {
	const [disabled, setDisabled] = useState(true);
	const [state, setState] = useState(null);

	const [loadPartnerPlans, partnerPlans] = useLazyQuery(
		gql`
		query PartnerPlans($partner_id: String!) {
			PartnerPlans(partner_id: $partner_id) {
				_id
				name
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const { loading, data, error, refetch } = useQuery(Queries.USER, {
		fetchPolicy: "no-cache",
		variables: {
			id,
		},
		onCompleted: (data) => {
			if (data) setState(data.User);
			if (data?.User?.partner_id) {
				loadPartnerPlans({
					variables: {
						partner_id: data.User.partner_id,
					},
				});
			}
		},
	});
	const [saveAction, { loading: loadingSave }] = useMutation(Queries.USER_SAVE);

	const getValue = (path) => {
		return _.get(state, path);
	};

	const setValue = (path, value) => {
		setState((state) => {
			_.set(state, path, value);
			return state;
		});
	};

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const save = async () => {
		if (!state.name) return ErrorDialog("Por favor, insira um nome válido");

		const { partner_name, certificate_url, ...rest } = state;

		let obj = {
			_id: id,
			...rest,
		};

		if (obj.status) obj.status = parseInt(obj.status);

		const { data, error } = await saveAction({
			variables: {
				input: obj,
			},
		});

		if (error) {
			ErrorDialog(
				"Não foi possível salvar as informações no momento. Tente novamente mais tarde."
			);
		} else {
			notification.success({
				message: "Sucesso",
				description: "Informações salvas com sucesso",
			});
			refetch();
		}
	};


	return (
		<Card
			title="Informações Básicas"
			extra={
				<Button onClick={() => setDisabled((state) => !state)}>
					{disabled ? "Editar" : "Cancelar"}
				</Button>
			}
		>
			{loading ? <Loading /> : null}

			{!loading && error ? <Error refetch={refetch} /> : null}

			{state && !loading && !error ? (
				<Form layout="vertical">
					<Row gutter={20}>
						<Col span={16}>
							<Form.Item name="name" label="Nome">
								<Input
									disabled={disabled}
									defaultValue={getValue("name")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("name", value);
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="phone" label="Fone">
								<MaskedInput
									mask="(11) 11111-1111"
									disabled={disabled}
									defaultValue={getValue("phone")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("phone", value);
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="email" label="E-mail">
								<Input
									disabled={disabled}
									defaultValue={getValue("email")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("email", value);
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="password" label="Senha">
								<Input
									type="password"
									disabled={disabled}
									onChange={(e) => {
										const { value } = e.target;
										setValue("password", value);
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="cpf" label="CPF">
								<MaskedInput
									mask="111.111.111-11"
									disabled={disabled}
									defaultValue={getValue("cpf")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("cpf", value);
									}}
								/>
							</Form.Item>
						</Col>

						{process?.env?.REACT_APP_MSP ? (
							<Col span={8}>
								<Form.Item label="Parceiros">
									<Select
										mode="multiple"
										allowClear
										style={{
											width: "100%",
										}}
										placeholder="Selecione parceiros"
										disabled={disabled}
										onChange={() => {

										}}
										options={[
											{
												label: "Parceiro 1",
												value: "1"
											},
											{
												label: "Parceiro 2",
												value: "2"
											},
											{
												label: "Parceiro 3",
												value: "3"
											}
										]}
									/>
								</Form.Item>
							</Col>
						) : (
							<Col span={8}>
								<Form.Item label="Parceiro">
									<PartnerSearch
										disabled={disabled}
										additionalProps={{
											allowClear: true,
										}}
										defaultValue={getValue("partner_name")}
										onChange={(value, name) => {
											if (!value) return setValue("partner_id", null);
											setValue("partner_id", value);
										}}
									/>
								</Form.Item>
							</Col>
						)}

						<Col span={8}>
							<Form.Item label="Plano">
								<Select
									disabled={disabled}
									defaultValue={state.plan}
									onChange={(e) => {
										setValue("plan", e);
									}}
								>
									<Select.Option value="plano_super_boleto">
										SUPER Boleto
									</Select.Option>
									<Select.Option value="plano_top_boleto">
										TOP Boleto
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>

						{state.partner_id ? (
							<Col span={8}>
								<Form.Item label="Plano Parceiro">
									<Select
										disabled={disabled}
										allowClear
										defaultValue={state.partner_plan}
										onChange={(e) => {
											if (!e) {
												setValue("partner_plan", null);
											} else {
												setValue("partner_plan", e);
											}
										}}
									>
										{partnerPlans?.data?.PartnerPlans?.length
											? partnerPlans?.data?.PartnerPlans.map((i) => (
													<Select.Option value={i._id}>{i.name}</Select.Option>
											  ))
											: null}
									</Select>
								</Form.Item>
							</Col>
						) : null}

						<Col span={8}>
							<Form.Item label="Status">
								<Select
									disabled={disabled}
									defaultValue={state.status.toString()}
									onChange={(e) => {
										setValue("status", e);
									}}
								>
									<Select.Option value="0">Expirado</Select.Option>
									<Select.Option value="1">Ativo</Select.Option>
									<Select.Option value="2">
										Não cadastrou/requisitou empresa
									</Select.Option>
									<Select.Option value="3">Aguardando Abertura</Select.Option>
									<Select.Option value="4">Inativo</Select.Option>
									<Select.Option value="5">Cancelado</Select.Option>
									<Select.Option value="6">Bloqueado</Select.Option>
								</Select>
							</Form.Item>
						</Col>

						

						<Divider />

						<Col span={8}>
							<div
								style={{
									display: "flex",
									gap: "10px",
									alignItems: "flex-end",
								}}
							>
								<Form.Item name="file" label="Certificado">
									<Upload
										name={"file"}
										maxCount={1}
										beforeUpload={() => false}
										onChange={(e) => {
											setValue("certificate", e.file);
										}}
										accept="application/x-pkcs12"
									>
										<Button icon={<UploadOutlined />}>Enviar</Button>
									</Upload>
								</Form.Item>
								{state.certificate_url && (
									<Button
										onClick={() => {
											window.open(state.certificate_url);
										}}
										type="primary"
										style={{
											marginBottom: 24,
										}}
									>
										Baixar certificado
									</Button>
								)}
							</div>
						</Col>

						<Col span={8}>
							<Form.Item label="Senha Certificado">
								<Input.Password
									defaultValue={state?.certificate_password || ""}
									disabled={disabled}
									onChange={(e) => {
										const { value } = e.target;
										setValue("certificate_password", value);
									}}
								/>
							</Form.Item>
						</Col>

						<Divider />

						<Col span={24}>
							<Form.Item label="Observações Gerais">
								<TextArea
									disabled={disabled}
									rows={4}
									maxLength={2000}
									defaultValue={state?.notes}
									onChange={(e) => {
										const { value } = e.target;
										setValue("notes", value);
									}}
								/>
							</Form.Item>
						</Col>

						{!disabled ? (
							<Col span={24}>
								<Button
									type="primary"
									style={{ float: "right" }}
									onClick={save}
									loading={loadingSave}
								>
									Salvar
								</Button>
							</Col>
						) : null}
					</Row>
				</Form>
			) : null}
		</Card>
	);
}

export default User;
